import React from "react";
import { useState, useEffect, useRef } from "react";
import CommunicateReports from "./Personality/CommunicateReports";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import ManageReports from "./Personality/ManageReports";
import { Avatar } from "flowbite-react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DareReports from "./Personality/DareReports";
import AdaptReports from "./Personality/AdaptReports";
import ExcelReports from "./Personality/ExcelReports";
import SoftSkills from "./SoftSkills";
import {
  getCognitiveReports,
  getSoftSkillReports,
} from "../../../../api/candidateReportsApi";
import Cognitive from "./Cognitive";
import PageSpinner from "../../../../Component/PageSpinner";
import EmptyState from "../Components/EmptyState";
import HardSkills from "./HardSkills";
import MotivationReports from "./Personality/MotivationReports";
import EmotionalQuotientReports from "./Personality/EmotionalQuotientReports";
import BtnSpinner from "../../../../Component/BtnSpinner";
import AntiCheat from "./AntiCheat";
import Personality from "./Overview/Personality";
import PersonalityTraitsReports from "./Personality/PersonalityTraitsReports";
import SoftSkillsOverview from "./Overview/SoftSkills";
import HardSkillsOverview from "./Overview/HardSkills";
import CognitiveOverview from "./Overview/Cognitive";
import { getDetailCandidateJob } from "../../../../api";
const PDFView = () => {
  const { id, candidate_job_id } = useParams();
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureLoading, setProfilePictureLoading] = useState(false);
  const [professionalBehavior, setProfessionalBehaviour] = useState([]);
  const [emotionalIntelligence, setEmotionalIntelligence] = useState([]);
  const [personalityTraits, setPersonalityTraits] = useState([]);
  const [motivation, setMotivation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [personalityLoading, setPersonalityLoading] = useState(true);
  const [personalLoaded, setPersonalLoaded] = useState(false);
  const [softSkillLoading, setSoftSkillLoading] = useState(true);
  const [hardSkillLoading, setHardSkillLoading] = useState(false);
  const [candidateJob, setCandidateJob] = useState({});
  const [cognitiveLoading, setCognitiveLoading] = useState(true);
  const [cognitives, setCognitives] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isExported, setIsExported] = useState(false);
  const [candidateJobLoading, setCandidateJobLoading] = useState(true);
  const [photoCaptures, setPhotoCaptures] = useState([]);
  const [profile, setProfile] = useState({
    job: {
      role: "",
    },
  });
  const getPersonal = async () => {
    setLoading(true);
    await axios
      .get(`/api/client/candidates/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setProfile(result.data);
          if (result.data.avatar_path) {
            getProfilePicture(result.data.avatar_path);
          }
        }
        setPersonalLoaded(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getProfilePicture = async (imageUrl) => {
    setProfilePictureLoading(true);
    const axiosInstance = axios.create({
      timeout: 7000, // Set the timeout here
    });
    try {
      const response = await axiosInstance.get(
        "/api/public/file-path/" + imageUrl,
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      setProfilePicture(URL.createObjectURL(blob));
      setProfilePictureLoading(false);
    } catch (error) {
      setProfilePictureLoading(false);
    }
  };

  const getPersonality = async () => {
    setPersonalityLoading(true);
    await axios
      .get(
        `/api/client/candidate/${id}/${candidate_job_id}/reports/personality`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          const professional_behaviour = result.data.find(
            (item) => item.test === "professional-behaviour"
          );
          const emotional_intelligence = result.data.find(
            (item) => item.test === "emotional-intelligence"
          );
          const personality_traits = result.data.find(
            (item) => item.test === "personality-traits"
          );
          if (professional_behaviour) {
            setProfessionalBehaviour(professional_behaviour);
            setMotivation(
              professional_behaviour.result.find(
                (item) => item.category === "Motivations Radar"
              )
            );
          }
          if (emotional_intelligence) {
            setEmotionalIntelligence(emotional_intelligence);
          }
          if (personality_traits) {
            setPersonalityTraits(personality_traits);
          }
          // const emotional_intelligence = result.data.find((item) => item.test === 'emotional_intelligence');
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setPersonalityLoading(false);
      });
  };

  const fetchSoftSkill = async () => {
    setSoftSkillLoading(true);
    const result = await getSoftSkillReports(id, candidate_job_id);
    if (result.success) {
      setSoftSkills(result.data);
    }
    setSoftSkillLoading(false);
  };

  const fetchCognitive = async () => {
    setCognitiveLoading(true);
    const result = await getCognitiveReports(id, candidate_job_id);
    if (result.success) {
      setCognitives(result.data);
    }
    setCognitiveLoading(false);
  };

  const fetchCandidateJob = async () => {
    setCandidateJobLoading(true);
    const result = await getDetailCandidateJob(candidate_job_id);
    if (result.success) {
      setCandidateJob(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setCandidateJobLoading(false);
  };

  const exportResult = async () => {
    let pdf = new jsPDF("p", "pt", "a4", true);

    // Replace these with the IDs of your components
    const pages = [
      // PAGE PERSONAL
      ["profile-result", "overview-result"],
      ["overview-soft-skill", "overview-hard-skill"],
      ["overview-cognitive"],
      ["anticheat-result"],
      // PAGE1
      [
        "t-personality-communicate",
        "radar-personality-communicate",
        "comm-1",
        "comm-2",
        "comm-3",
        "comm-4",
        "empty-communicate-result",
      ],
      // PAGE2
      [
        "t-personality-manage",
        "radar-personality-manage",
        "mng-1",
        "mng-2",
        "mng-3",
        "mng-4",
        "empty-management-result",
      ],
      // PAGE3
      [
        "t-personality-daring",
        "radar-personality-daring",
        "drg-1",
        "drg-2",
        "drg-3",
        "drg-4",
        "empty-dare-result",
      ],
      // PAGE4
      [
        "t-personality-adaption",
        "radar-personality-adaption",
        "adapt-1",
        "adapt-2",
        "adapt-3",
        "adapt-4",
        "empty-adapt-result",
      ],
      // PAGE4
      [
        "t-personality-excel",
        "radar-personality-excel",
        "excel-1",
        "excel-2",
        "excel-3",
        "excel-4",
        "empty-excel-result",
      ],
      // PAGE5
      [
        "t-personality-motivation",
        "radar-personality-motivation",
        "motrad-1",
        "motrad-2",
        "motrad-3",
        "motrad-4",
        "motrad-5",
        "empty-motivation-result",
      ],
      // PAGE6
      [
        "t-personality-motivation",
        "motrad-6",
        "motrad-7",
        "motrad-8",
        "empty-motivation-result",
      ],
      // PAGE7
      [
        "t-personality-eq",
        "radar-personality-eq",
        "tagging-eq",
        "empty-eq-result",
      ],
      [
        "t-personality-traits",
        "radar-personality-traits",
        "disc-d",
        "disc-i",
        "disc-s",
        "disc-c",
        "empty-personality-traits-result",
      ],

      // SOFT SKILLS
      ["soft-skills-communication"],
      ["customer-service"],
      ["negotiation-skills"],
      ["sales-skills"],
      ["body-language"],
      ["influential-skills"],
      ["communication-action-based"],

      // COGNITIVE
      ["intelligence-quotient-iq"],
      ["critical-thinking"],
      ["numeric-fundamental-indo"],
      ["numeric-fundamental-eng"],
      ["numeric-eng"],
      ["numeric-indo"],
      ["verbal-assessment-advanced"],
      ["verbal-assessment-begineer"],
      ["business-acumen"],
      ["human-capital"],
      // PAGE8
      // ["hardskill-result"],
      // PAGE8
      // ["cognitive-result"],
    ];

    let currentPageHeight = 0;
    const pageHeight = pdf.internal.pageSize.height;
    const lastIndex = pages.length - 1;
    try {
      for (const [index, elements] of pages.entries()) {
        let yValue = 0;
        for (const elementId of elements) {
          const element = document.getElementById(elementId);
          if (element) {
            if (yValue > pageHeight - 100) {
              pdf.addPage();
              yValue = 10;
            }
            // Calculate the scaling factor to fit the content within the page width
            try {
              let canvas = await html2canvas(element);
              let pdfWidth = pdf.internal.pageSize.width;
              let scaleFactor = pdfWidth / canvas.width;
              let imgData = canvas.toDataURL("image/png", 0.9);
              pdf.addImage(
                imgData,
                "PNG",
                0,
                yValue,
                pdfWidth,
                canvas.height * scaleFactor,
                "",
                "FAST"
              );
              yValue += canvas.height * scaleFactor + 10;
            } catch (error) {
              continue;
            }
          }
        }
        if (yValue > 0) {
          if (index !== lastIndex) {
            pdf.addPage();
          }
        }
      }
      // Save the PDF
      pdf.save("candidate_reports.pdf");
      setIsExported(true);
    } catch (error) {
      toast.error(error, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    getPersonal();
    fetchCandidateJob();
    getPersonality();
    fetchSoftSkill();
    fetchCognitive();
  }, []);

  // useEffect(() => {
  //     setTimeout(() => {
  //         if (!profilePicture) {
  //             setProfilePictureLoading(false);
  //         }
  //     }, 6000)
  // },[profilePictureLoading, profilePicture])
  useEffect(() => {
    if (
      !personalityLoading &&
      !softSkillLoading &&
      !cognitiveLoading &&
      !profilePictureLoading &&
      !candidateJobLoading
    ) {
      setIsExporting(true);
      setTimeout(() => {
        exportResult();
        // handleDrawCanvas();
      }, 3000);
    }
  }, [
    personalityLoading,
    softSkillLoading,
    cognitiveLoading,
    profilePictureLoading,
    candidateJobLoading,
  ]);

  return (
    <>
      <div
        className="fixed items-center flex bottom-[2%] right-[42.5%] px-5 py-5 rounded-lg"
        style={{ background: "rgb(32 32 32 / 76%)", zIndex: "999" }}
      >
        {!isExported && (
          <div className="mr-2">
            <BtnSpinner />
          </div>
        )}
        <p className="text-gray-200">
          {isExporting
            ? isExported
              ? "Data Berhasil Di Export"
              : "Proses Export Data..."
            : "Sedang Memuat Data..."}
        </p>
      </div>
      <div className="w-full justify-center flex">
        {/* <div id="pdf-content">
                    <canvas ref={canvasRef} width={300} height={100} />
                </div> */}
        <div id="export-me" className="w-[1080px]">
          <div
            className="flex w-full lg:space-x-2 md:flex-row flex-col mt-2 mb-3 px-4 py-2"
            id="profile-result"
          >
            <div className="card w-[100%] md:w-[50%] flex">
              {loading ? (
                <PageSpinner />
              ) : (
                <>
                  <div className="avatar xl:w-[20%] w-[30%] flex items-center">
                    <div className="w-full">
                      <div className="h-[70px] w-[70px] lg:h-[80px] lg:w-[80px] xl:w-[80px] xl:h-[80px] overflow-hidden rounded-full">
                        {profilePictureLoading && <PageSpinner />}
                        {!profilePictureLoading && (
                          <>
                            {profilePicture ? (
                              <img
                                alt="avatar"
                                className="w-[100%] object-center object-cover rounded-[10px] h-full object-cover object-center"
                                src={profilePicture}
                              />
                            ) : (
                              <Avatar
                                size={"lg"}
                                alt="User settings"
                                rounded={true}
                                src={profile.avatar}
                              />
                            )}
                          </>
                        )}

                        {/* <img alt='avatar' className='w-[100%] object-center object-cover rounded-[10px] h-full object-cover object-center' src={profile.avatar} /> */}
                      </div>
                    </div>
                  </div>
                  <div className="avatar xl:w-[80%]" id="lol">
                    <div className="flex flex-col xl:flex-row">
                      <div className="xl:w-[60%] w-full">
                        <p className="text-base xl:text-lg  font-bold mb-1">
                          {profile.name}
                        </p>
                        <p className="text-sm  text-gray-600 mb-1">
                          {profile.email}
                        </p>
                        <p className="text-sm text-gray-600 mb-1">
                          {profile.phonenumber}
                        </p>
                      </div>
                      <div className="avatar xl:w-[40%] w-full mt-2 xl:mt-0 flex xl:justify-end justify-start"></div>
                    </div>
                    {/* <p className='text-gray-600'>Link</p> */}
                  </div>
                </>
              )}
            </div>
            <div className="card w-[100%] md:w-[50%] flex ">
              {loading ? (
                <PageSpinner />
              ) : (
                <>
                  <div className="avatar w-[33%]">
                    <p className="font-semibold mb-1">Role</p>
                    <p className="text-sm text-gray-600">{profile.job.role}</p>
                  </div>
                  <div className="avatar w-[33%]">
                    <p className="font-semibold mb-1">Completed</p>
                    <p className="text-sm text-gray-600">
                      {profile.job.status_label}
                    </p>
                  </div>
                  <div className="avatar w-[33%]">
                    <p className="font-semibold mb-1">Status</p>
                    <p className="text-sm text-gray-600 px-2 bg-green-100 text-green-800 max-w-max rounded-lg font-semibold">
                      {profile.job.rating_status_label}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="px-4" id="overview-result">
            {personalityLoading ? (
              <PageSpinner padding={"10px 0"} />
            ) : (
              <Personality
                professionalBehaviour={professionalBehavior}
                motivation={motivation}
                emotional={emotionalIntelligence}
                personalityTraits={personalityTraits}
              />
            )}
          </div>

          <div className="px-4" id="overview-soft-skill">
            {softSkillLoading ? (
              <PageSpinner padding={"10px 0"} />
            ) : (
              <>
                {softSkills.filter((item) => item.is_choosen).length > 0 && (
                  <>
                    <SoftSkillsOverview data={softSkills} />
                  </>
                )}
              </>
            )}
          </div>
          {/* <div className="px-4" id="overview-hard-skill">
                        {hardSkillLoading ? <PageSpinner padding={"10px 0"} /> : <HardSkillsOverview />}
                    </div> */}

          <div className="px-4" id="overview-cognitive">
            {cognitiveLoading ? (
              <PageSpinner padding={"10px 0"} />
            ) : (
              <>
                {cognitives.filter((item) => item.is_choosen).length > 0 && (
                  <>
                    <CognitiveOverview data={cognitives} />
                  </>
                )}
              </>
            )}
          </div>
          <div className="px-4 py-2" id="anticheat-result">
            {candidateJobLoading ? (
              <PageSpinner padding={"10px 0"} />
            ) : (
              <AntiCheat records={candidateJob.records} />
            )}
          </div>
          {personalityLoading ? (
            <PageSpinner />
          ) : (
            <>
              {professionalBehavior.is_choosen && (
                <>
                  <div id="communicate-result" className="mb-[30px]">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-communicate"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">
                        Professional Behaviour - Communicate
                      </p>
                    </div>
                    {professionalBehavior.result.length > 0 && (
                      <>
                        <div>
                          <CommunicateReports
                            communication={professionalBehavior.result.find(
                              (item) => item.category === "Communication"
                            )}
                          />
                        </div>
                      </>
                    )}
                    {professionalBehavior.result.length <= 0 && (
                      <>
                        <div id="empty-communicate-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                  <div id="management-result">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-manage"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">
                        Professional Behaviour - Manage
                      </p>
                    </div>
                    {professionalBehavior.result.length > 0 && (
                      <>
                        <div>
                          <ManageReports
                            management={professionalBehavior.result.find(
                              (item) => item.category === "Management"
                            )}
                          />
                        </div>
                      </>
                    )}
                    {professionalBehavior.result.length <= 0 && (
                      <>
                        <div id="empty-management-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                  <div id="dare-result">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-daring"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">
                        Professional Behaviour - Daring
                      </p>
                    </div>
                    {professionalBehavior.result.length > 0 && (
                      <>
                        <div>
                          <DareReports
                            data={professionalBehavior.result.find(
                              (item) => item.category === "Daring"
                            )}
                          />
                        </div>
                      </>
                    )}
                    {professionalBehavior.result.length <= 0 && (
                      <>
                        <div id="empty-dare-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                  <div id="adapt-result">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-adaption"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">
                        Professional Behaviour - Adaption
                      </p>
                    </div>
                    {professionalBehavior.result.length > 0 && (
                      <>
                        <div>
                          <AdaptReports
                            data={professionalBehavior.result.find(
                              (item) => item.category === "Adaption"
                            )}
                          />
                        </div>
                      </>
                    )}
                    {professionalBehavior.result.length <= 0 && (
                      <>
                        <div id="empty-adapt-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                  <div id="excel-result">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-excel"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">
                        Professional Behaviour - Excel
                      </p>
                    </div>
                    {professionalBehavior.result.length > 0 && (
                      <>
                        <div>
                          <ExcelReports
                            data={professionalBehavior.result.find(
                              (item) => item.category === "Excel"
                            )}
                          />
                        </div>
                      </>
                    )}
                    {professionalBehavior.result.length <= 0 && (
                      <>
                        <div id="empty-excel-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                  <div id="motivation-result">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-motivation"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">Motivation</p>
                    </div>
                    {professionalBehavior.result.length > 0 && (
                      <>
                        <div>
                          <MotivationReports
                            data={professionalBehavior.result.find(
                              (item) => item.category === "Motivations Radar"
                            )}
                          />
                        </div>
                      </>
                    )}
                    {professionalBehavior.result.length <= 0 && (
                      <>
                        <div id="empty-motivation-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {emotionalIntelligence.is_choosen && (
                <>
                  <div id="eq-result">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-eq"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">
                        Emotional Quotient
                      </p>
                    </div>
                    {emotionalIntelligence.result.length > 0 && (
                      <>
                        <div>
                          <EmotionalQuotientReports
                            data={emotionalIntelligence.result[0]}
                          />
                        </div>
                      </>
                    )}
                    {emotionalIntelligence.result.length <= 0 && (
                      <>
                        <div id="empty-eq-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {personalityTraits.is_choosen && (
                <>
                  <div id="personality-traits-result">
                    <div
                      className="bg-gray-100 px-5 py-4"
                      id="t-personality-traits"
                    >
                      <p className="text-gray-900 font-bold mb-2 text-[20px]">
                        Personality
                      </p>
                      <p className="text-gray-900 text-[18px]">
                        Personality Traits
                      </p>
                    </div>
                    {personalityTraits.result.length > 0 && (
                      <>
                        <div>
                          <PersonalityTraitsReports
                            data={personalityTraits.result[0]}
                          />
                        </div>
                      </>
                    )}
                    {personalityTraits.result.length <= 0 && (
                      <>
                        <div id="empty-traits-result">
                          <EmptyState />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {softSkills.filter((item) => item.is_choosen).length > 0 && (
            <>
              <hr className="mt-4 mb-4" />
              <div id="softskill-result">
                {/* <div className="bg-gray-100 px-5 py-5 text-[20px]">
                  <p className="text-gray-900 font-bold text-[18px]">
                    Soft Skills
                  </p>
                </div> */}
                {softSkillLoading ? (
                  <PageSpinner padding={"10px 0"} />
                ) : (
                  <>
                    <SoftSkills data={softSkills} />
                  </>
                )}
              </div>
            </>
          )}
          {/* <hr className="mt-4 mb-4" />
                    <div id="hardskill-result">
                        <div className="bg-gray-100 px-5 py-5 text-[20px]">
                            <p className="text-gray-900 font-bold text-[18px]">Hard Skills</p>
                        </div>
                        <HardSkills data={cognitives} />
                    </div> */}

          {cognitives.filter((item) => item.is_choosen).length > 0 && (
            <>
              <hr className="mt-4 mb-4" />
              <div id="cognitive-result">
                {/* <div className="bg-gray-100 px-5 py-5 text-[20px]">
                  <p className="text-gray-900 font-bold text-[18px]">
                    Cognitives
                  </p>
                </div> */}
                {cognitiveLoading ? (
                  <PageSpinner padding={"10px 0"} />
                ) : (
                  <>
                    <Cognitive data={cognitives} />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default PDFView;
