import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import { BriefcaseIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
// import JobList from "./JobList";
// import BreadCrumb from "../../../Component/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { documentTitle } from "../../../../helpers";
import { useSelector } from "react-redux";
import {
  getCandidateCompareCognitive,
  getCandidateCompareSoftSkill,
  getPersonalityCandidates,
  getSoftSkillReports,
} from "../../../../api";
import SoftSkillsOverview from "../../MyCandidatesPage/Tabs/Overview/SoftSkills";
import PageSpinner from "../../../../Component/PageSpinner";
import ComparisonGantChart from "./Components/ComparisonGantChart";
import ComparisonRadarChart from "./Components/ComparisonRadarChart";
import ComparisonPersonalityRadarChart from "./Components/ComparisonPersonalityRadarChart";
import ComparisonFloatingAdd from "../../../../Component/ComparisonFloatingAdd";
const CandidateComparisonPage = () => {
  const [softSkills, setSoftSkills] = useState([]);
  const [cognitives, setCognitives] = useState([]);
  const [personalities, setPersonalities] = useState([]);
  const [softSkillLoading, setSoftSkillLoading] = useState(true);
  const [cognitiveLoading, setCognitiveLoading] = useState(true);
  const [personalityLoading, setPersonalityLoading] = useState(true);
  const [candidateIds, setCandidateIds] = useState([]);
  let navigate = useNavigate();
  const { position_slug } = useParams();
  const permission = useSelector((state) => state.user.permissions);
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [activeTab, setActiveTab] = useState("personality");
  const handleAddCandidate = async (candidates) => {
    navigate(
      `/client/my-candidates/${position_slug}/comparison?candidates=${candidates
        .map((candidate) => candidate.candidate_job_id + "-" + candidate.id)
        .join(",")}`,
      { replace: true }
    );
    getData();
  };
  const fetchSoftSkill = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const candidates = urlParams.get("candidates");
    setSoftSkillLoading(true);
    const softSkillResult = await getCandidateCompareSoftSkill(candidates);
    const cognitiveResult = await getCandidateCompareCognitive(candidates);
    if (softSkillResult.success && cognitiveResult.success) {
      // MERGE SOFT SKILL AND COGNITIVE DATA
      const softSkills = softSkillResult.data;
      const cognitives = cognitiveResult.data;
      const merged = [...softSkills, ...cognitives];
      // MERGE BY CANDIDATE ID ON MERGED ARRAY
      const mergedData = merged.reduce((acc, current) => {
        const existingCandidate = acc.find(
          (item) => item.candidate.id === current.candidate.id
        );

        if (existingCandidate) {
          existingCandidate.result.push(...current.result);
        } else {
          acc.push(current);
        }

        return acc;
      }, []);
      setSoftSkills(mergedData);
    }
    setSoftSkillLoading(false);
  };
  // const fetchCognitive = async () => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const candidates = urlParams.get("candidates");
  //   setCognitiveLoading(true);
  //   const result = await getCandidateCompareCognitive(candidates);
  //   if (result.success) {
  //     setCognitives(result.data);
  //   }
  //   setCognitiveLoading(false);
  // };
  const fetchPersonality = async () => {
    setPersonalityLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const candidates = urlParams.get("candidates");
    const result = await getPersonalityCandidates(candidates);
    if (result.success) {
      setPersonalities(result.data);
    }
    setPersonalityLoading(false);
  };

  const getData = async () => {
    setSoftSkillLoading(true);
    // setCognitiveLoading(true);
    setPersonalityLoading(true);
    await fetchPersonality();
    await fetchSoftSkill();
    // await fetchCognitive();
  };

  useEffect(() => {
    documentTitle("Candidate Comparison");
    getData();

    const urlParams = new URLSearchParams(window.location.search);
    const candidates = urlParams.get("candidates");
    setCandidateIds(candidates.split(","));
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <ComparisonFloatingAdd
        candidateIds={candidateIds}
        companyJobPositionId={position_slug}
        onApply={(candidates) => {
          handleAddCandidate(candidates);
        }}
      />
      <nav className="bg-gray-200 rounded-md p-2">
        <div className="flex space-x-4 overflow-x-auto  ">
          <a
            href="#"
            className={`${
              activeTab === "personality"
                ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("personality");
            }}
          >
            Personality
          </a>
          {/* CHECK IS CHOOSEN */}

          <a
            href="#"
            className={`${
              activeTab === "soft-skills"
                ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("soft-skills");
            }}
          >
            Soft Skills & Cognitive
          </a>

          {/* <a
            href="#"
            className={`${
              activeTab === "cognitive"
                ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg"
                : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("cognitive");
            }}
          >
            Cognitive
          </a> */}
        </div>
      </nav>
      <div className="flex flex-col gap-2 mt-2 px-3">
        {activeTab === "personality" && (
          <div className="flex flex-col gap-[40px]">
            {personalityLoading && <PageSpinner />}
            {!personalityLoading && (
              <ComparisonPersonalityRadarChart data={personalities} />
            )}
          </div>
        )}
        {activeTab === "soft-skills" && (
          <div className="flex flex-col gap-[40px]">
            {softSkillLoading && <PageSpinner />}
            {!softSkillLoading && <ComparisonRadarChart data={softSkills} />}
            {!softSkillLoading && <ComparisonGantChart data={softSkills} />}
          </div>
        )}
        {/* {activeTab === "cognitive" && (
          <div className="flex flex-col gap-[40px]">
            {cognitiveLoading && <PageSpinner />}
            {!cognitiveLoading && <ComparisonRadarChart data={cognitives} />}
            {!cognitiveLoading && <ComparisonGantChart data={cognitives} />}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CandidateComparisonPage;
