import axios from 'axios';
import moment from 'moment';

export const getUtilityCandidateInJobPosition = async (companyJobPositionId) => {
    const response = await axios
        .get(`/api/client/utility/candidates-job-position/${companyJobPositionId}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    return response.data
}