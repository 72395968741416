import React from "react";
import { useState, useEffect } from "react";
// import RangeList2 from "../Components/RangeList2";
import PageSpinner from "../../../../../Component/PageSpinner";
import CustomRadarChart from "../../../../../Component/CustomRadarChart";
import { normalizeToPercentage } from "../../../../../helpers";
const ComparisonRadarChart = ({ data }) => {
  const [results, setResults] = useState(null);
  const [topResult, setTopResult] = useState(null);
  const [remapGroup, setRemapGroup] = useState([]);
  const [filteredRemapGroup, setFilteredRemapGroup] = useState([]);
  const [ranked, setRanked] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const colorRanges = [
    {
      gr1: "rgba(60, 89, 252, 0.7)", // #3c59fc
      gr2: "rgba(118, 0, 224, 0.7)", // #7600e0
    },
    {
      gr1: "rgba(255, 86, 49, 0.7)", // #ff5631
      gr2: "rgba(255, 22, 75, 0.7)", // #ff164b
    },
    {
      gr1: "rgba(197, 230, 38, 0.7)", // #c5e626
      gr2: "rgba(64, 230, 39, 0.7)", // #40e627
    },
    {
      gr1: "rgba(255, 205, 0, 0.7)", // #ffcd00
      gr2: "rgba(255, 86, 49, 0.7)", // #ff5631
    },
    {
      gr1: "rgba(0, 254, 176, 0.7)", // #00feb0
      gr2: "rgba(57, 195, 224, 0.7)", // #39c3e0
    },
  ];

  const calculateRanked = (topData) => {
    const remapped = {};
    data.forEach((item) => {
      item.result.forEach((test) => {
        if (!remapped[test.test]) {
          remapped[test.test] = {
            test: test.test,
            test_name: test.test_name,
            candidates: [],
          };
        }

        remapped[test.test].candidates.push({
          name: item.candidate.fullname,
          avatar: item.candidate.avatar,
          result: test.result,
          total_tag_test: test.total_tag_test,
          total_max_value: test.total_max_value,
          total_min_value: test.total_min_value,
          total_result: test.total_result,
          custom_result_label: test.custom_result_label,
          is_choosen: test.is_choosen,
          result_percentage: parseInt(
            normalizeToPercentage(
              test.total_result,
              test.total_min_value,
              test.total_max_value
            )
          ),
        });
      });
    });

    let remappedArray = Object.values(remapped);
    // Filter out tests that are not the highest for the top result candidate
    remappedArray = remappedArray.filter((test) => {
      const topCandidate = test.candidates.find(
        (candidate) => candidate.name === topData.name
      );
      return (
        topCandidate &&
        topCandidate.total_result ===
          Math.max(...test.candidates.map((c) => c.total_result))
      );
    });

    // Sort the array so that the top result candidate's tests are first
    remappedArray.sort((a, b) => {
      const aTopCandidate = a.candidates.find(
        (candidate) => candidate.name === topData.name
      );
      const bTopCandidate = b.candidates.find(
        (candidate) => candidate.name === topData.name
      );
      return bTopCandidate.total_result - aTopCandidate.total_result;
    });
    setRemapGroup(remappedArray);
  };

  useEffect(() => {
    if (data) {
      let labels = [];
      let values = [];
      let candidates = [];

      data[0].result.forEach((item) => {
        labels.push(item.test_name);
      });

      

      values = data.map((item, key) => {
        return {
          label: item.candidate.fullname,
          data: item.result.map((res) => {
            return parseInt(normalizeToPercentage(res.total_result, res.total_min_value, res.total_max_value));
          }),
          backgroundColor: ({ chart }) => {
            const ctx = chart.ctx;
            const width = 200; // lebar kanvas
            const height = 200; // tinggi kanvas
            const degree = 134; // sudut gradien dalam derajat

            // Konversi derajat ke radian
            const radian = degree * (Math.PI / 180);

            // Titik pusat kanvas (opsional, tergantung di mana Anda mulai)
            const centerX = width / 2;
            const centerY = height / 2;

            // Panjang relatif gradien (untuk menentukan seberapa jauh titik akhir)
            const length = Math.sqrt(width * width + height * height);

            // Koordinat titik awal (bisa disesuaikan, misalnya di pojok kiri atas)
            const x0 = 0;
            const y0 = 0;

            // Koordinat titik akhir menggunakan trigonometri
            const x1 = centerX + length * Math.cos(radian);
            const y1 = centerY + length * Math.sin(radian);

            if (!isNaN(x1) && !isNaN(y1)) {
              const gradient = ctx.createLinearGradient(x0, y0, x1, y1);
              gradient.addColorStop(0, colorRanges[key].gr1);
              gradient.addColorStop(1, colorRanges[key].gr2);
              return gradient;
            }
          },
          borderColor: colorRanges[key].gr1,
          borderWidth: 1,
          hidden: "",
          // pointBackgroundColor: "#fff", // Set the point background color
          pointBorderColor: colorRanges[key].gr1, // Set the point border color
          pointBorderWidth: 3, // Set the point border width
          // pointHoverBackgroundColor: "#fff", // Set the point hover background color
          // pointHoverBorderColor: "#e7e8e7", // Set the point hover border color
        };
      });

      values.sort((a, b) => {
        const totalA = a.data.reduce((sum, value) => sum + value, 0);
        const totalB = b.data.reduce((sum, value) => sum + value, 0);
        return totalB - totalA;
      });

      // SORT CANDIDATES BY DESCENDING TOTAL RESULT
      candidates = values.map((item) => item.label);
      setCandidates(candidates);

      setResults({
        labels: labels,
        datasets: values,
      });

      setActiveTab(values[0].label);
      // Get one of the biggest results from values
      // let topResult = values.reduce((prev, current) => {
      //   const prevTotal = prev.data.reduce((a, b) => a + b, 0);
      //   const currentTotal = current.data.reduce((a, b) => a + b, 0);
      //   return prevTotal > currentTotal ? prev : current;
      // });

      // let topResult = values[0];

      // setTopResult({
      //   name: topResult.label,
      //   value: topResult.data,
      //   total_all_result: topResult.data.reduce((a, b) => a + b, 0),
      // });
      // calculateRanked({
      //   name: topResult.label,
      //   values: values,
      //   total_all_result: topResult.data.reduce((a, b) => a + b, 0),
      // });
    }
  }, [data]);

  useEffect(() => {
    if (activeTab && results) {
      let topResult = results.datasets.find((item) => item.label === activeTab);
      setTopResult({
        name: topResult.label,
        value: topResult.data,
        total_all_result: topResult.data.reduce((a, b) => a + b, 0),
      });
      calculateRanked({
        name: topResult.label,
        values: results.datasets,
        total_all_result: topResult.data.reduce((a, b) => a + b, 0),
      });

      // REARRANGE THE RADAR CHART SORT BY LABEL OF ACTIVE TAB
      let newResults = { ...results };
      newResults.datasets = [...newResults.datasets].sort((a, b) => {
        return a.label === activeTab ? -1 : b.label === activeTab ? 1 : 0;
      });
      console.log(newResults);
      setResults(newResults);
    }
  }, [activeTab]);

  useEffect(() => {
    if (remapGroup.length > 0 && topResult) {
      const filteredRemapGroup = remapGroup.filter((item) => {
        const topCandidate = item.candidates.find(
          (candidate) => candidate.name === topResult.name
        );
        const hasLowerResult = item.candidates.some(
          (candidate) =>
            candidate.name !== topResult.name &&
            candidate.result_percentage < topCandidate.result_percentage
        );
        return topCandidate && hasLowerResult;
      });
      setFilteredRemapGroup(filteredRemapGroup);
    }
  }, [remapGroup, topResult]);
  return (
    <div className="mt-3">
      <div className="mb-6">
        <nav className="border-b border-1 rounded-md p-2">
          <div className="flex space-x-4 overflow-x-auto  ">
            {candidates.map((candidate) => (
              <a
                href="#"
                className={`${
                  activeTab === candidate
                    ? "text-[#0384EDFF] py-2 px-4 font-semibold bg-[#F3FFFBFF] rounded-lg flex-shrink-0 border border-1"
                    : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0 border border-1 rounded-lg"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(candidate);
                }}
              >
                {candidate}
              </a>
            ))}
          </div>
        </nav>
      </div>
      <div className="flex flex-col items-center ">
        <div className="flex w-full mb-7 mdb:mb-5 gap-10 px-2 mdb:px-4 flex-col mdb:flex-row">
          <div className="w-full flex justify-center mdb:w-[50%]">
            <div className="max-w-[420px] w-full h-full">
              {results ? <CustomRadarChart data={results} /> : <PageSpinner />}
            </div>
          </div>
          <div className="w-full mdb:w-[50%]">
            <h1 className="font-semibold mb-4 text-xl">
              Why {topResult?.name} better than another ?
            </h1>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                {filteredRemapGroup.length === 0 && (
                  <p className="text-gray-500">-</p>
                )}
                {filteredRemapGroup.length > 0 &&
                  filteredRemapGroup.map((item, key) => (
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text text-gray-500">
                        {item.test_name}
                      </p>
                      <p className="text-gray-500 ">
                        {item.candidates.map((candidate, index) => (
                          <>
                            <i
                              className={`${
                                candidate.name == topResult.name
                                  ? "font-semibold text-green-500"
                                  : ""
                              } mr-1`}
                            >
                              {candidate.result_percentage}%
                            </i>
                            {index < item.candidates.length - 1 ? " vs " : ""}
                          </>
                        ))}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonRadarChart;
