import React, { Component } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
class CustomRadarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      options: {
        scales: {
          r: {
            grid: {
              circular: true,
            },
            suggestedMin: 1, // Set the minimum value to 1
            suggestedMax: this.props.maxValue ? this.props.maxValue : 10, // Set the maximum value to 10
            beginAtZero: true,
            pointLabels: {
              font: {
                size: 12, // Set the font size here
              },
              callback: function (value, index, values) {
                const maxLineLength = 10; // Adjust this value as needed
                if (value.length > maxLineLength) {
                  const words = value.split(" ");
                  let lines = [];
                  let currentLine = words[0];

                  for (let i = 1; i < words.length; i++) {
                    if (currentLine.length + words[i].length + 1 <= maxLineLength) {
                      currentLine += " " + words[i];
                    } else {
                      lines.push(currentLine);
                      currentLine = words[i];
                    }
                  }
                  lines.push(currentLine);
                  return lines;
                } else {
                  return value;
                }
              },
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.maxValue !== this.props.maxValue) {
      this.setState({
        data: this.props.data,
        options: {
          ...this.state.options,
          scales: {
            ...this.state.options.scales,
            r: {
              ...this.state.options.scales.r,
              suggestedMax: this.props.maxValue ? this.props.maxValue : 10,
            },
          },
        },
      });
    }
  }

  render() {
    return <Radar data={this.state.data} options={this.state.options} />;
  }
}

export default CustomRadarChart;
